function getPercentageNewOld(newValue, oldValue) {
  if(oldValue == 0 && newValue == 0) {
    return (0).toFixed(2);
  }else if (oldValue == 0) {
    return (newValue   * 100).toFixed(2);
  }else if (newValue == 0) {
    return -(oldValue   * 100).toFixed(2);
  } else {
    return ((newValue / oldValue)  * 100).toFixed(2);
  }
}

function getPercentageOfTotal(value, total) {
    return ((value / total)  * 100).toFixed(0);
}

function replaceNonNumericCharacters(string) {
  return string.replace(/\D/g,'');
}

(function() {

  var PVNDashboard = angular.module('PVN', ['PVN.directives', 'PVN.controllers'], ['$interpolateProvider', function($interpolateProvider) {
    $interpolateProvider.startSymbol('<<');
    $interpolateProvider.endSymbol('>>');
  }]);

  PVNDashboard.run(function() {
    moment.locale('nl');
  });

})();
