(function() {
  var PVNControllers = angular.module('PVN.controllers', ['PVN.services']);

  PVNControllers.controller('AppController', ['$scope', '$mdDialog', 'ItemService', '$http', 'apiUrl', 'errorHandler', function($scope, $mdDialog, ItemService, $http, apiUrl, errorHandler) {
    moment.locale('nl');

    $scope.toggleMenu = function() {
      $http({	method: 'GET',
        url: apiUrl + 'admin/toggleMenu',
      }).then(function(res){
        console.log(res);
      }, errorHandler);
    }

    $scope.getAlert = function(alert) {
      $scope.loadAlert = true;
      $scope.alert = alert;
      ItemService.getAlert($scope.alert.id).then(function(result) {
        $scope.alert = result;
        var parentEl = angular.element(document.body);
        $scope.DialogController = function($scope, $mdDialog, alert) {
          $scope.alert = alert;
          console.log($scope.alert);
          $scope.closeDialog = function() {
            $mdDialog.hide();
          };
        };

        $mdDialog.show({
            parent: parentEl,
            controller: $scope.DialogController,
            templateUrl: 'assets/templates/alertpopup.html',
            locals: {
              alert : $scope.alert,
            },
         });


      });
    };

    $scope.goToURL = function(url) {
      window.location = url;
    };

    setTimeout(function () {
      $(".live-tile").liveTile();
    }, 300);

  }]);

  PVNControllers.controller('HomeController', ['$scope', 'ItemService', function($scope, ItemService) {
    $scope.model = {};
    $scope.model.zones = zones;
    $scope.model.alerts = alerts;
    //$scope.model.categories = categories;
    //Totaal inwoners
    $scope.model.habitants = $scope.model.zones[0].inhabitants;
    //geschiedenis bezoekers per dag
    $scope.model.analyticsData = analyticsData;

    $scope.model.visitorsData = $scope.model.analyticsData.visitorsData;
    //Totaal gebruikers
    $scope.model.usersData = $scope.model.analyticsData.usersData.totalsForAllResults['ga:users'];
    //Nieuwe gebruikers sinds laatste maand
    $scope.model.newUsersData = $scope.model.analyticsData.newusersData.totalsForAllResults['ga:users'];

    //Percentage gebruikers ten opzichte van totaal inwoners
    $scope.model.habitantsUserRatio =  getPercentageNewOld($scope.model.usersData, $scope.model.habitants);

    //Percentage nieuwe gebruikers van totaal gebruikers.
    $scope.model.usersIncreaseRate = getPercentageNewOld($scope.model.newUsersData, $scope.model.usersData);

    console.log($scope.model);

    var map = new GMaps({
      div: '#map',
      lat: $scope.model.zones[0].lat,
      lng: $scope.model.zones[0].lon
    });


    angular.forEach($scope.model.alerts, function(alert, key) {

      var iconimg = {
         url: 'assets/img/onderwerpen/'+alert.icon,// url
         scaledSize: new google.maps.Size(30, 32), // size
      };

      map.addMarker({
        title: alert.title,
        lat: alert.lat,
        lng: alert.lon,
        icon: iconimg,
        click: function(e) {
          $scope.getAlert(alert);
        }
      });
    });


    $scope.countAlertsDaily = function() {
      var n = 0
      angular.forEach($scope.model.alerts, function(value, key) {
        var createdDate = new Date(Date.parse(value.created_at));
        var todayDate = new Date();
        if(todayDate.toDateString() == createdDate.toDateString()) {
          n++;
        }
      });
      return n;
    };

    $scope.countAlertsMonthly = function() {
      var n = 0
      angular.forEach($scope.model.alerts, function(value, key) {
        var createdDate = new Date(Date.parse(value.created_at));
        var todayDate = new Date();
        if(createdDate.getMonth() == todayDate.getMonth() && createdDate.getYear() == todayDate.getYear()) {
          n++;
        }
      });
      return n;
    };

    // $scope.countAlertsPercentageSinceLastMonthInCat = function(categorie, isinteger) {
    //     var percentage;
    //     if(isinteger === true) {
    //       percentage = getPercentageNewOld(categorie.thisMonthsAlertCount,categorie.lastMonthsAlertCount);
    //     }else {
    //       percentage = '<i class="icon-custom-down"></i>'+getPercentageNewOld(categorie.thisMonthsAlertCount,categorie.lastMonthsAlertCount) + '%';
    //     }
    //     return percentage;
    // };
  }]);

  PVNControllers.controller('CategoriesController', ['$scope', 'ItemService', function($scope, ItemService) {
    $scope.model = {};
    $scope.model.zones = zones;
    $scope.model.categories = categories;
    $scope.model.alerts = alerts;
    //Totaal inwoners
    $scope.model.habitants = $scope.model.zones[0].inhabitants;
    //geschiedenis bezoekers per dag
    $scope.model.analyticsData = analyticsData;

    $scope.model.visitorsData = $scope.model.analyticsData.visitorsData;
    //Totaal gebruikers
    $scope.model.usersData = $scope.model.analyticsData.usersData.totalsForAllResults['ga:users'];
    //Nieuwe gebruikers sinds laatste maand
    $scope.model.newUsersData = $scope.model.analyticsData.newusersData.totalsForAllResults['ga:users'];

    //Percentage gebruikers ten opzichte van totaal inwoners
    $scope.model.habitantsUserRatio =  getPercentageNewOld($scope.model.usersData, $scope.model.habitants);

    //Percentage nieuwe gebruikers van totaal gebruikers.
    $scope.model.usersIncreaseRate = getPercentageNewOld($scope.model.newUsersData, $scope.model.usersData);

    var dataVerdelingOnderwerp = [];
    var dataPopulariteitOnderwerp = [];
    var dataReactiesOnderwerp = [];
    var dataDonutColors = [];
    angular.forEach($scope.model.categories, function(categorie, key) {
      dataVerdelingOnderwerp.push({
        'label' : categorie.category,
        'id' : categorie.id,
        'value' : getPercentageOfTotal(categorie.totalAlertCount, $scope.model.alerts.length)
      });

      dataPopulariteitOnderwerp.push({
        'label' : categorie.category,
        'id' : categorie.id,
        'value' : categorie.totalViewCount
      });

      dataReactiesOnderwerp.push({
        'label' : categorie.category,
        'id' : categorie.id,
        'value' : categorie.totalReplyCount
      });

      dataDonutColors.push(
        categorie.background_color
      );

    });

    Morris.Donut({
      data : dataVerdelingOnderwerp,
      element : 'zone-categorie-verdeling',
      colors : dataDonutColors,
      gridTextFamily: 'Open Sans',
      gridTextColor: '#1b1e24',
      formatter: function (value, data) { return value + '% van totaal'; }
    }).on('click', function(i, row){
      $scope.goToURL('/dashboard/categories/' + row.id);
    });

    Morris.Donut({
      data : dataPopulariteitOnderwerp,
      element : 'zone-categorie-populariteit',
      colors : dataDonutColors,
      gridTextFamily: 'Open Sans',
      gridTextColor: '#1b1e24',
      formatter: function (value, data) { return value + ' keer bekeken'; }
    }).on('click', function(i, row){
      $scope.goToURL('/dashboard/categories/' + row.id);
    });

    Morris.Donut({
      data : dataReactiesOnderwerp,
      element : 'zone-categorie-reacties',
      colors : dataDonutColors,
      gridTextFamily: 'Open Sans',
      gridTextColor: '#1b1e24',
      formatter: function (value, data) { return value + ' reacties geplaatst'; }
    }).on('click', function(i, row){
      $scope.goToURL('/dashboard/categories/' + row.id);
    });

    $scope.countAlertsDaily = function() {
      var n = 0
      angular.forEach($scope.model.alerts, function(value, key) {
        var createdDate = new Date(Date.parse(value.created_at));
        var todayDate = new Date();
        if(todayDate.toDateString() == createdDate.toDateString()) {
          n++;
        }
      });
      return n;
    }

    $scope.countAlertsMonthly = function() {
      var n = 0
      angular.forEach($scope.model.alerts, function(value, key) {
        var createdDate = new Date(Date.parse(value.created_at));
        var todayDate = new Date();
        if(createdDate.getMonth() == todayDate.getMonth() && createdDate.getYear() == todayDate.getYear()) {
          n++;
        }
      });
      return n;
    }

    $scope.countAlertsPercentageSinceLastMonthInCat = function(categorie, isinteger) {
        var percentage;
        if(isinteger === true) {
          percentage = getPercentageNewOld(categorie.thisMonthsAlertCount,categorie.lastMonthsAlertCount);
        }else {
          percentage = '<i class="icon-custom-down"></i>'+getPercentageNewOld(categorie.thisMonthsAlertCount,categorie.lastMonthsAlertCount) + '%';
        }
        return percentage;
    };
  }]);


  PVNControllers.controller('AlertsController', ['$scope', 'ItemService', '$http', 'apiUrl', 'errorHandler', '$mdToast', function($scope, ItemService, $http, apiUrl, errorHandler, $mdToast) {
    $scope.model = {};
    $scope.model.zones = zones;
    $scope.model.categories = categories;
    //Totaal inwoners
    $scope.model.habitants = $scope.model.zones[0].inhabitants;
    //geschiedenis bezoekers per dag
    $scope.model.analyticsData = analyticsData;

    $scope.model.visitorsData = $scope.model.analyticsData.visitorsData;
    //Totaal gebruikers
    $scope.model.usersData = $scope.model.analyticsData.usersData.totalsForAllResults['ga:users'];
    //Nieuwe gebruikers sinds laatste maand
    $scope.model.newUsersData = $scope.model.analyticsData.newusersData.totalsForAllResults['ga:users'];

    //Percentage gebruikers ten opzichte van totaal inwoners
    $scope.model.habitantsUserRatio =  getPercentageNewOld($scope.model.usersData, $scope.model.habitants);

    //Percentage nieuwe gebruikers van totaal gebruikers.
    $scope.model.usersIncreaseRate = getPercentageNewOld($scope.model.newUsersData, $scope.model.usersData);

    $scope.inProgress = true;
    $scope.loadMoreInProgress = false;
    $scope.skip = 40;
    $scope.take = 40;
    $scope.model.orderBy = {};
    $scope.model.orderBy.hide = '0';
    $scope.model.orderBy.type = 'created_at';
    $scope.model.orderBy.mode = "true";
    $scope.model.orderBy.category = 0;

    $scope.$watch('model.orderBy', function() {
      $scope.inProgress = true;
      $scope.skip = 0;
      $scope.take = 40;
      $http({	method: 'post',
        url: apiUrl + 'alerts/loadAlerts',
        data: {
          'orderBy' : $scope.model.orderBy,
          'skip' : $scope.skip,
          'take' : $scope.take,
        },
      }).then(function(res){
        $scope.model.alerts = res.data.alerts;
        $scope.skip = $scope.skip + 40;
        $scope.inProgress = false;
      }, errorHandler);
    }, true);

    $scope.loadMoreAlerts = function() {
      $scope.loadMoreInProgress = true;
      $http({	method: 'post',
        url: apiUrl + 'alerts/loadAlerts',
        data: {
          'orderBy' : $scope.model.orderBy,
          'skip' : $scope.skip,
          'take' : $scope.take
        },
      }).then(function(res){
        $scope.skip = $scope.skip + 40;
        $scope.model.alerts.push.apply($scope.model.alerts, res.data.alerts);
        $scope.loadMoreInProgress = false;
      }, errorHandler);
    };

    $scope.removeAlert = function(alert) {
      $http({	method: 'post',
              url: apiUrl + 'alert/remove',
              data: {'alert' : alert},
      }).then(function(res){
        alert.hide = 1;
      }, errorHandler);
    };

    $scope.noticeAlert = function(alert) {
      $http({	method: 'post',
              url: apiUrl + 'alert/notice',
              data: {'alert' : alert},
      }).then(function(res){
        $mdToast.show(
          $mdToast.simple()
          .content(res.data.message)
          .position("right top")
          .hideDelay(5000)
        );
      }, errorHandler);
    };

  }]);

  PVNControllers.controller('AlertController', ['$scope', '$http', 'apiUrl', 'errorHandler', '$mdToast', function($scope, $http, apiUrl, errorHandler, $mdToast) {
    $scope.model = {};
    $scope.data = {};
    $scope.model = model;
    $scope.model.reply = false;
    $scope.model.showReplyArea = false;

    $scope.removeAlert = function(alert) {
      $http({	method: 'post',
              url: apiUrl + 'alert/remove',
              data: {'alert' : alert},
      }).then(function(res){
        alert.hide = 1;
      }, errorHandler);
    };

    $scope.noticeAlert = function(alert) {
      $http({	method: 'post',
              url: apiUrl + 'alert/notice',
              data: {'alert' : alert},
      }).then(function(res){
        $mdToast.show(
          $mdToast.simple()
          .content(res.data.message)
          .position("right top")
          .hideDelay(5000)
        );
      }, errorHandler);
    };

    $scope.writeReply = function() {
      $scope.model.showReplyArea = true;
    };

    $scope.postReply = function() {
      if($scope.data.reply !== undefined) {
        $http({	method: 'post',
                url: apiUrl + 'alert/adminReply',
                data: {
                  'alert' : $scope.model.alert,
                  'reply' : $scope.data.reply
                },
        }).then(function(res){
          $mdToast.show(
            $mdToast.simple()
              .content(res.data.message)
              .position("right top")
              .hideDelay(5000)
          );
          $scope.model.replies.push(res.data.reply);
          $scope.model.showReplyArea = false;
        }, errorHandler);
        console.log($scope.data.reply);
      }
    };

    $scope.removeReply = function(reply) {
      $http({	method: 'post',
              url: apiUrl + 'alert/removeReply',
              data: {
                'alert' : $scope.model.alert,
                'reply' : reply
              },
      }).then(function(res){
        $mdToast.show(
          $mdToast.simple()
            .content(res.data.message)
            .position("right top")
            .hideDelay(5000)
        );
        var index = $scope.model.replies.indexOf(reply);
        if (index > -1) {
          $scope.model.replies.splice(index, 1);
        }
      }, errorHandler);
      console.log($scope.data.reply);
    };

    console.log($scope.model);

    var map = new GMaps({
      div: '#map',
      lat: $scope.model.alert.lat,
      lng: $scope.model.alert.lon
    });


    var iconimg = {
       url: 'assets/img/onderwerpen/'+$scope.model.categorie.icon,// url
       scaledSize: new google.maps.Size(30, 32), // size
    };

    map.addMarker({
      title: $scope.model.alert.title,
      lat: $scope.model.alert.lat,
      lng: $scope.model.alert.lon,
      icon: iconimg,
    });

    console.log($scope.model);
    (function () {
      if (window.addtocalendar)if(typeof window.addtocalendar.start == "function")return;
      if (window.ifaddtocalendar == undefined) { window.ifaddtocalendar = 1;
          var d = document, s = d.createElement('script'), g = 'getElementsByTagName';
          s.type = 'text/javascript';s.charset = 'UTF-8';s.async = true;
          s.src = ('https:' == window.location.protocol ? 'https' : 'http')+'://addtocalendar.com/atc/1.5/atc.min.js';
          var h = d[g]('body')[0];h.appendChild(s); }})();

  }]);

  PVNControllers.controller('UsersController', ['$scope', 'ItemService', '$http', 'apiUrl', 'errorHandler', '$mdToast', function($scope, ItemService, $http, apiUrl, errorHandler, $mdToast) {
    $scope.model = {};
    $scope.skip = 0;
    $scope.take = 30;
    $scope.model.orderBy = {};
    $scope.model.orderBy.hide = '0';
    $scope.model.orderBy.type = 'created_at';
    $scope.model.orderBy.mode = 'DESC';
    $scope.inProgress = true;
    $scope.loadMoreInProgress = false;
    $scope.$watch('model.orderBy', function() {
      $scope.inProgress = true;
      $scope.skip = 0;
      $scope.take = 30;
      $http({	method: 'post',
        url: apiUrl + 'users/getUsers',
        data: {
          'orderBy' : $scope.model.orderBy,
          'skip' : $scope.skip,
          'take' : $scope.take
        },
      }).then(function(res){
        $scope.model.users = res.data.users;
        $scope.skip = $scope.skip + 30;
        $scope.inProgress = false;
      }, errorHandler);
    }, true);

    $scope.loadMoreUsers = function() {
      $scope.loadMoreInProgress = true;
      $http({	method: 'post',
        url: apiUrl + 'users/getUsers',
        data: {
          'orderBy' : $scope.model.orderBy,
          'skip' : $scope.skip,
          'take' : $scope.take
        },
      }).then(function(res){
        $scope.skip = $scope.skip + 30;
        $scope.model.users.push.apply($scope.model.users, res.data.users);
        $scope.loadMoreInProgress = false;
      }, errorHandler);
    };

  }]);

  PVNControllers.controller('OfficialUsersController', ['$scope', 'ItemService', '$http', 'apiUrl', 'errorHandler', '$mdToast', function($scope, ItemService, $http, apiUrl, errorHandler, $mdToast) {
    $scope.model = {};
    $scope.skip = 0;
    $scope.take = 30;
    $scope.model.orderBy = {};
    $scope.model.orderBy.hide = '0';
    $scope.model.orderBy.type = 'created_at';
    $scope.model.orderBy.mode = 'DESC';

    $scope.$watch('model.orderBy', function() {
      $scope.skip = 0;
      $scope.take = 30;
      $http({	method: 'post',
        url: apiUrl + 'users/getOfficialUsers',
        data: {
          'orderBy' : $scope.model.orderBy,
          'skip' : $scope.skip,
          'take' : $scope.take
        },
      }).then(function(res){
        $scope.model.users = res.data.users;
        $scope.skip = $scope.skip + 30;
      }, errorHandler);
    }, true);

    $scope.loadMoreUsers = function() {
      $http({	method: 'post',
        url: apiUrl + 'users/getOfficialUsers',
        data: {
          'orderBy' : $scope.model.orderBy,
          'skip' : $scope.skip,
          'take' : $scope.take
        },
      }).then(function(res){
        $scope.skip = $scope.skip + 30;
        $scope.model.users.push.apply($scope.model.users, res.data.users);
      }, errorHandler);
    };
  }]);

  PVNControllers.controller('AdminController', ['$scope', function($scope) {
    $scope.model = {};
    $scope.model.admin = admin;
    $scope.model.zone = zone;
    $scope.model.user = user;
  }]);
  PVNControllers.controller('AdminsController', ['$scope', function($scope) {
    $scope.model = {};
    $scope.model.admins = admins;
    $scope.showForm = false;
    $scope.newUser = function() {
      $scope.showForm = true;
    }
    $scope.cancel = function() {
      $scope.showForm = false;
    }
  }]);
  PVNControllers.controller('LandingsPagesController', ['$scope', function($scope) {
    $scope.model = {};
    $scope.model.landingspages = landingspages;
    $scope.showForm = false;
    $scope.landingspage = {};
    $scope.newLandingsPage = function() {
      $scope.showForm = true;
    };
    $scope.edit = function(landingspage) {
      $scope.showForm = true;
      $scope.landingspage = landingspage;
      $('#text-editor').data("wysihtml5").editor.setValue(landingspage.description);
      $('#hiddentext').data("wysihtml5").editor.setValue(landingspage.hiddentext);
      //$.each(, function(index, value) {
        $('#tags').tagsinput('add', landingspage.meta_keywords);
      //});
    };

    $scope.cancel = function() {
      $scope.showForm = false;
    };

    //HTML5 editor
  	$('#text-editor').wysihtml5();
  	$('#hiddentext').wysihtml5();
    $("#multi").select2();
    //Single instance of tag inputs  -  can be initiated with simply using data-role="tagsinput" attribute in any input field
    $('#tags').tagsinput({
      typeahead: {
        source: ['veiligheid-apps','buurt','buren','veiligheid app','veiligheid','veilige buurt','buurtpreventie app','goa app','politie','onrust','vandalisme','vandalisme app','inbraak','buurt app','veiligheids app']
      }
    });


    //Drag n Drop up-loader
  }]);

  PVNControllers.controller('SettingsController', ['$scope', '$http', 'apiUrl', 'errorHandler', '$mdToast', function($scope, $http, apiUrl, errorHandler, $mdToast) {
    $scope.model = {};
    $scope.model.adminReplies = adminReplies;
    $scope.form = {};
    $scope.form.show = false;
    $scope.form.title = '';
    $scope.form.description = '';

    $scope.openNewReplyForm = function() {
      $scope.form.show = true;
    };

    $scope.postReply = function() {
      $http({	method: 'post',
        url: apiUrl + 'adminReplies/postReply',
        data: {
          'reply' : $scope.form
        },
      }).then(function(res){
        if(res.data.isValid === false) {
          $mdToast.show(
            $mdToast.simple()
            .content(res.data.message)
            .position("right top")
            .hideDelay(5000)
          );
        } else {
          $mdToast.show(
            $mdToast.simple()
            .content(res.data.message)
            .position("right top")
            .hideDelay(5000)
          );

          $scope.model.adminReplies.push(res.data.reply);
          console.log($scope.model.adminReplies);
          $scope.form = {};
          $scope.form.show = false;
          $scope.form.title = '';
          $scope.form.description = '';
        }
      }, errorHandler);
    };

    $scope.editReply = function(reply) {
      $http({	method: 'post',
        url: apiUrl + 'adminReplies/editReply',
        data: {
          'reply' : reply
        },
      }).then(function(res){
        if(res.data.isValid === false) {
          $mdToast.show(
            $mdToast.simple()
            .content(res.data.message)
            .position("right top")
            .hideDelay(5000)
          );
        } else {
          $mdToast.show(
            $mdToast.simple()
            .content(res.data.message)
            .position("right top")
            .hideDelay(5000)
          );
          reply.edit = false;
          console.log($scope.model.adminReplies);
        }
      }, errorHandler);
    };

    $scope.deleteReply = function(reply) {
      $http({	method: 'post',
        url: apiUrl + 'adminReplies/deleteReply',
        data: {
          'reply' : reply
        },
      }).then(function(res){
        if(res.data.isValid === false) {
          $mdToast.show(
            $mdToast.simple()
            .content(res.data.message)
            .position("right top")
            .hideDelay(5000)
          );
        } else {
          $mdToast.show(
            $mdToast.simple()
            .content(res.data.message)
            .position("right top")
            .hideDelay(5000)
          );

          var index = $scope.model.adminReplies.indexOf(reply);
          if (index > -1) {
            $scope.model.adminReplies.splice(index, 1);
          }
          console.log($scope.model.adminReplies);
        }
      }, errorHandler);
    };

  }]);

  PVNControllers.controller('CategorieController', ['$scope','ItemService', function($scope, ItemService) {
    $scope.model = {};
    $scope.model.alerts = alerts;
    $scope.model.categorie = categorie;
    $scope.model.subcategories = subcategories;
    $scope.model.zones = zones;
    //Totaal inwoners
    $scope.model.habitants = $scope.model.zones[0].inhabitants;
    //geschiedenis bezoekers per dag
    $scope.model.analyticsData = analyticsData;

    $scope.model.visitorsData = $scope.model.analyticsData.visitorsData;
    //Totaal gebruikers
    $scope.model.usersData = $scope.model.analyticsData.usersData.totalsForAllResults['ga:users'];
    //Nieuwe gebruikers sinds laatste maand
    $scope.model.newUsersData = $scope.model.analyticsData.newusersData.totalsForAllResults['ga:users'];

    //Percentage gebruikers ten opzichte van totaal inwoners
    $scope.model.habitantsUserRatio =  getPercentageNewOld($scope.model.usersData, $scope.model.habitants);

    //Percentage nieuwe gebruikers van totaal gebruikers.
    $scope.model.usersIncreaseRate = getPercentageNewOld($scope.model.newUsersData, $scope.model.usersData);

    console.log($scope.model);

    var map = new GMaps({
      div: '#map',
      lat: $scope.model.zones[0].lat,
      lng: $scope.model.zones[0].lon
    });



    angular.forEach($scope.model.alerts, function(alert, key) {

      var iconimg = {
         url: 'assets/img/onderwerpen/'+alert.icon,// url
         scaledSize: new google.maps.Size(30, 32), // size
      };

      map.addMarker({
        title: alert.title,
        lat: alert.lat,
        lng: alert.lon,
        icon: iconimg,
        click: function(e) {
          $scope.getAlert(alert);
        }
      });
    });
    //Count Alerts with corresponding categorie id
    $scope.countAlertsInCat = function(catid){
      var n = 0;
      angular.forEach($scope.model.subcategories, function(categorie, key) {
        if(categorie.id == catid) {
          angular.forEach($scope.model.alerts, function(value, key) {
            if(categorie.id == value.cat_id) {
              n++;
            }
          });
        }
      });

      return n;
    }


    $scope.countAlertsDaily = function() {
      var n = 0
      angular.forEach($scope.model.alerts, function(value, key) {
        var createdDate = new Date(Date.parse(value.created_at));
        var todayDate = new Date();
        if(todayDate.toDateString() == createdDate.toDateString()) {
          n++;
        }
      });
      return n;
    }

    $scope.countAlertsMonthly = function() {
      var n = 0
      angular.forEach($scope.model.alerts, function(value, key) {
        var createdDate = new Date(Date.parse(value.created_at));
        var todayDate = new Date();
        if(createdDate.getMonth() == todayDate.getMonth() && createdDate.getYear() == todayDate.getYear()) {
          n++;
        }
      });
      return n;
    }

    $scope.countAlertsPercentageSinceLastMonthInCat = function(categorie, isinteger) {
      var percentage;
      if(isinteger === true) {
        percentage = getPercentageNewOld(categorie.thisMonthsAlertCount,categorie.lastMonthsAlertCount);
      }else {
        percentage = '<i class="icon-custom-down"></i>'+getPercentageNewOld(categorie.thisMonthsAlertCount,categorie.lastMonthsAlertCount) + '%';
      }
      return percentage;
    };

  }]);


  PVNControllers.controller('ZoneController', ['$scope', 'ItemService', function($scope, ItemService) {
    $scope.model = {};
    $scope.model.zone = zone;
    $scope.model.alerts = alerts;
    $scope.model.categories = categories;
    //Totaal inwoners
    $scope.model.habitants = $scope.model.zone.inhabitants;
    //geschiedenis bezoekers per dag
    $scope.model.analyticsData = analyticsData;
    $scope.model.visitorsData = $scope.model.analyticsData.visitorsData;
    //Totaal gebruikers
    $scope.model.usersData = $scope.model.analyticsData.usersData.totalsForAllResults['ga:users'];
    //Nieuwe gebruikers sinds laatste maand
    $scope.model.newUsersData = $scope.model.analyticsData.newusersData.totalsForAllResults['ga:users'];

    //Percentage gebruikers ten opzichte van totaal inwoners
    $scope.model.habitantsUserRatio =  getPercentageNewOld($scope.model.usersData, $scope.model.habitants);

    //Percentage nieuwe gebruikers van totaal gebruikers.
    $scope.model.usersIncreaseRate = getPercentageNewOld($scope.model.newUsersData, $scope.model.usersData);

    console.log($scope.model);
    var map = new GMaps({
      div: '#map',
      lat: $scope.model.zone.lat,
      lng: $scope.model.zone.lon
    });

    angular.forEach($scope.model.alerts, function(alert, key) {

      var iconimg = {
         url: 'assets/img/onderwerpen/'+alert.icon,// url
         scaledSize: new google.maps.Size(30, 32), // size
      };

      map.addMarker({
        title: alert.title,
        lat: alert.lat,
        lng: alert.lon,
        icon: iconimg,
        click: function(e) {
          $scope.getAlert(alert);
        }
      });
    });

    var dataVerdelingOnderwerp = [];
    var dataPopulariteitOnderwerp = [];
    var dataReactiesOnderwerp = [];
    var dataDonutColors = [];
    angular.forEach($scope.model.categories, function(categorie, key) {
      dataVerdelingOnderwerp.push({
        'label' : categorie.category,
        'id': categorie.id,
        'value' : getPercentageOfTotal(categorie.totalAlertCount, $scope.model.alerts.length)
      });

      dataPopulariteitOnderwerp.push({
        'label' : categorie.category,
        'id': categorie.id,
        'value' : categorie.totalViewCount
      });

      dataReactiesOnderwerp.push({
        'label' : categorie.category,
        'id': categorie.id,
        'value' : categorie.totalReplyCount
      });

      dataDonutColors.push(
        categorie.background_color
      );

    });

    Morris.Donut({
      data : dataVerdelingOnderwerp,
      element : 'zone-categorie-verdeling',
      colors : dataDonutColors,
      gridTextFamily: 'Open Sans',
      gridTextColor: '#1b1e24',
      formatter: function (value, data) { return value + '% van totaal'; }
    }).on('click', function(i, row){
      $scope.goToURL('/dashboard/categories/' + row.id);
    });;

    Morris.Donut({
      data : dataPopulariteitOnderwerp,
      element : 'zone-categorie-populariteit',
      colors : dataDonutColors,
      gridTextFamily: 'Open Sans',
      gridTextColor: '#1b1e24',
      formatter: function (value, data) { return value + ' keer bekeken'; }
    }).on('click', function(i, row){
      $scope.goToURL('/dashboard/categories/' + row.id);
    });;

    Morris.Donut({
      data : dataReactiesOnderwerp,
      element : 'zone-categorie-reacties',
      colors : dataDonutColors,
      gridTextFamily: 'Open Sans',
      gridTextColor: '#1b1e24',
      formatter: function (value, data) { return value + ' reacties geplaatst'; }
    }).on('click', function(i, row){
      $scope.goToURL('/dashboard/categories/' + row.id);
    });;

    $scope.countAlertsDaily = function() {
      var n = 0
      angular.forEach($scope.model.alerts, function(value, key) {
        var createdDate = new Date(Date.parse(value.created_at));
        var todayDate = new Date();
        if(todayDate.toDateString() == createdDate.toDateString()) {
          n++;
        }
      });
      return n;
    }

    $scope.countAlertsMonthly = function() {
      var n = 0
      angular.forEach($scope.model.alerts, function(value, key) {
        var createdDate = new Date(Date.parse(value.created_at));
        var todayDate = new Date();
        if(createdDate.getMonth() == todayDate.getMonth() && createdDate.getYear() == todayDate.getYear()) {
          n++;
        }
      });
      return n;
    }

    $scope.countAlertsPercentageSinceLastMonthInCat = function(categorie, isinteger) {
        var percentage;
        if(isinteger === true) {
          percentage = getPercentageNewOld(categorie.thisMonthsAlertCount,categorie.lastMonthsAlertCount);
        }else {
          percentage = '<i class="icon-custom-down"></i>'+getPercentageNewOld(categorie.thisMonthsAlertCount,categorie.lastMonthsAlertCount) + '%';
        }
        return percentage;
    };
  }]);
  
})();
