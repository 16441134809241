(function() {
  var PVNDirectives = angular.module('PVN.directives', ['PVN.services']);
  PVNDirectives.directive('pvnDatePicker', ['$compile', function($compile) {
    return {
      restrict: 'AE',
      link: function(scope, elem, attrs) {
        var name, dateTime;
        name = attrs.name;
        dateTime = attrs.value;
        if(dateTime == 'now') {
          dateTime = new Date();
        } else {
          dateTime = new Date(dateTime);
        }

        //dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
        dateTime = moment(dateTime).format("DD-MM-YYYY");
        elem.append('<div class="input-append success date col-md-12 col-lg-12" id="'+name+'">' +
            '<input type="text" name="'+name+'" value="'+dateTime+'" class="form-control">' +
            '<span class="add-on background-pvn"><span class="arrow text-pvn"></span><i class="fa fa-th"></i></span>' +
          '</div>');
        elem.$compile;

        $(document).ready(function() {
          $('#'+name).datepicker({
            defaultDate : dateTime,
            format : "dd-mm-yyyy"
          });
        });
      }
    };
  }]);

  PVNDirectives.directive('pvnUserPopup', ['ItemService', '$mdDialog', function(ItemService, $mdDialog) {
    return {
      restrict: 'AE',
      scope: {
          user_id: '=pvnUserPopup'
      },
      link: function(scope, elem, attrs) {
        elem.hover(function() {
          elem.css('cursor', 'pointer');
        });

        elem.on("click", function() {
          ItemService.getUser(scope.user_id).then(function(result) {
            scope.user = result.user;
            scope.following = result.following;
            var parentEl = angular.element(document.body);
            $mdDialog.show({
                parent: parentEl,
                controller: DialogController,
                templateUrl: 'assets/templates/userpopup.html',
                locals: {
                  user : scope.user,
                  following: scope.following
                },
             });

             function DialogController($scope, $mdDialog, user, following) {
               $scope.user = user;
               $scope.following = following;
               console.log($scope.user);
               $scope.closeDialog = function() {
                 $mdDialog.hide();
               }
             }
          });
        });
      }
    };
  }]);

  PVNDirectives.directive('pvnAlertPopup', ['ItemService', '$mdDialog', function(ItemService, $mdDialog) {
    return {
      restrict: 'AE',
      scope: {
          alert_id: '=pvnAlertPopup'
      },
      link: function(scope, elem, attrs) {
        elem.hover(function() {
          elem.css('cursor', 'pointer');
        });

        elem.on("click", function() {
          ItemService.getAlert(scope.alert_id).then(function(result) {
            scope.alert = result;
            var parentEl = angular.element(document.body);
            $mdDialog.show({
                parent: parentEl,
                controller: DialogController,
                templateUrl: 'assets/templates/alertpopup.html',
                locals: {
                  alert : scope.alert,
                },
             });

             function DialogController($scope, $mdDialog, alert) {
               $scope.alert = alert;
               console.log($scope.alert);
               $scope.closeDialog = function() {
                 $mdDialog.hide();
               };
             }
          });
        });
      }
    };
  }]);

  PVNDirectives.directive('pvnAlertChart' ,function() {
    return {
      restrict: 'AE',
      scope: {
          alerts: '=pvnAlertChart'
      },
      link: function(scope, elem, attrs) {
        elem.append('<div class="row">' +
                '<div class="col-md-12">'+
                  '<div class="grid simple">'+
                    '<div class="grid-title no-border">'+
                    '  <h4>Tijdlijn<span class="semi-bold"> Alerts</span></h4>'+
                      '<div class="tools"> <a href="javascript:;" class="collapse"></a></div>'+
                    '</div>'+
                    '<div class="grid-body no-border">'+
                      '<div class="row">'+
                        '<div class="col-md-12">'+
                          '<div id="zone-tijdlijn-chart" class="chart"> </div>'+
                        '</div>'+
                      '</div>'+
                    '</div>'+
                  '</div>'+
                '</div>'+
              '</div>');
        var counts = {};

        for(var i=0;i< scope.alerts.length;i++)
        {
          var key = scope.alerts[i].created_at;
          month = moment(key).format("YYYY-MM");

          counts[month] = {
            'month' : month,
            'value' : (counts[month]) ? counts[month].value + 1 : 1
          };
        }
        var dataChart = [];
        angular.forEach(counts, function(count, key) {
          dataChart.push(count);
        })

        new Morris.Line({
          // ID of the element in which to draw the chart.
          element: 'zone-tijdlijn-chart',
          // Chart data records -- each entry in this array corresponds to a point on
          // the chart.
          data: dataChart,
          // The name of the data record attribute that contains x-values.
          xkey: 'month',
          // A list of names of data record attributes that contain y-values.
          ykeys: ['value'],
          // Labels for the ykeys -- will be displayed when you hover over the
          // chart.
          labels: ['Alerts'],
          xLabelFormat: function(d) {
            return moment(d).format('MMMM YYYY');
          },
        });
      }
    };
  });

  PVNDirectives.directive('pvnCurrentTime', ['$interval', function($interval) {

    function link(scope, element, attrs) {
      var format,
          timeoutId;

      function updateTime() {
        element.text(moment().format('dddd, D MMMM YYYY, h:mm'));
      }

      scope.$watch(attrs.myCurrentTime, function(value) {
        format = value;
        updateTime();
      });

      element.on('$destroy', function() {
        $interval.cancel(timeoutId);
      });

      // start the UI update process; save the timeoutId for canceling
      timeoutId = $interval(function() {
        updateTime(); // update DOM
      }, 1000);
    }

    return {
      link: link
    };
  }]);

  PVNDirectives.directive('pvnImg', ['$mdDialog', function($mdDialog) {
    return {
      restrict: 'AE',
      scope: {
          user_id: '=pvnUserPopup'
      },
      link: function(scope, elem, attrs) {
        elem.hover(function() {
          elem.css('cursor', 'pointer');
        });

        elem.on("click", function() {
          ItemService.getUser(scope.user_id).then(function(result) {
            scope.user = result.user;
            scope.following = result.following;
            var parentEl = angular.element(document.body);
            $mdDialog.show({
                parent: parentEl,
                controller: DialogController,
                templateUrl: 'assets/templates/userpopup.html',
                locals: {
                  user : scope.user,
                  following: scope.following
                },
             });

             function DialogController($scope, $mdDialog, user, following) {
               $scope.user = user;
               $scope.following = following;
               console.log($scope.user);
               $scope.closeDialog = function() {
                 $mdDialog.hide();
               };
             }

             $scope.closeDialog = function() {
               $mdDialog.hide();
             };
          });
        });
      }
    };
  }]);

  PVNDirectives.directive('pvnCheckImage', ['$http', function($http) {
      return {
          restrict: 'A',
          link: function(scope, element, attrs) {
              attrs.$observe('ngSrc', function(ngSrc) {
  							if(ngSrc !== undefined) {
  								$http.get(ngSrc).success(function(){}).error(function(){
  									element.attr('src', 'https://wealert.nl/WeAlertApi/img/no_img.jpg'); // set default image
  								});
  							}
              });
          }
      };
  }]);
  
})();
