(function() {
	var PVNServices = angular.module('PVN.services', ['ngSanitize','ngMaterial']);
	var host = window.location.hostname;
	if(host == 'localhost') {
			//is development
			PVNServices.constant('apiUrl', 'https://localhost:8000/dashboard/api/');
	} else {
			// is production
			PVNServices.constant('apiUrl', 'https://platformveilignederland.nl/dashboard/api/');
	}
	PVNServices.factory('ItemService', ['$q', 'apiUrl', '$http', 'errorHandler', function($q, apiUrl, $http, errorHandler) {
		return {
			getAlert: function(alert_id, user_id, categorie_id) {
				return $http({	method: 'get',
								url: apiUrl + 'alerts/'+alert_id,
				}).then(function(res){
	        return res.data
	      }, errorHandler);
			},
	    getCategorie: function(categorie_id) {
	      return $http({	method: 'get',
	              url: apiUrl + 'categorie/'+categorie_id,
	      }).then(function(res){
	        return res.data
	      }, errorHandler);
	    },
			getZone: function(zone_id) {
	      return $http({	method: 'get',
	              url: apiUrl + 'zone/'+zone_id,
	      }).then(function(res){
	        return res.data
	      }, errorHandler);
	    },
			getUser: function(user_id) {
				return $http({	method: 'get',
								url: apiUrl + 'user/'+user_id,
				}).then(function(res){
					return res.data
				}, errorHandler);
			},
	  };
	}]);

	PVNServices.factory('errorHandler', ['$mdToast', function($mdToast) {
		return function(error) {
			var message;
	/*		var statusErrorMap = {
				'400' : "Server understood the request, but request content was invalid.",
				'401' : "Unauthorized access.",
				'403' : "Forbidden resource can't be accessed.",
				'500' : "Internal server error.",
				'503' : "Service unavailable."
			};*/
	    console.log(error)
			var statusErrorMap = {
				'400' : "Er ging iets mis met her versturen van de data.",
				'401' : "U bent niet geauthoriseerd.",
				'403' : "Dit is een beveiligde functie.",
				'500' : "Server fout, neem contact op met de beheerder.",
				'503' : "Er is een tijdelijke storing."
			};
			console.log(error);
			if (error.status) {
				message = statusErrorMap[error.status];
			}
			if(!message){
					 message="Er ging iets mis.";
			}else if(error.exception=='parsererror'){
				message="Er ging iets mis.";
			}else if(error.exception=='timeout'){
				message="Er ging iets mis... bent u verbonden met het internet?";
			}else if(error.exception=='abort'){
				message="Er ging iets mis.";
			}else {
				message="Er ging iets mis.";
			}
	    $mdToast.show(
	      $mdToast.simple()
				.content(message)
				.position("right top")
				.hideDelay(5000)
	    );
		};
	}]);

})();
